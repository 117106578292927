import { defineStore } from 'pinia';

export const useAuthStore = defineStore('auth', {
  state: () => ({
    isAuthenticated: false,
  }),
  actions: {
    login(accessToken, appRefreshToken) {
      this.isAuthenticated = true;
      localStorage.setItem('access_token', accessToken);
      localStorage.setItem('refresh_token', appRefreshToken);
    },
    logout() {
      this.isAuthenticated = false;
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
    },
    checkAuth() {
      const token = localStorage.getItem('access_token');
      this.isAuthenticated = !!token;
    },
  },
});
