<template>
  <div class="flex">
    <NavBarLayout />
    <div class="flex-1 dark:bg-black min-h-screen bg-gray-100">
      <div class="flex flex-col h-full mt-14 md:mt-0">
        <div
          v-if="shouldShowButton"
          class="flex flex-col justify-center items-center gap-1 flex-1 text-base md:text-lg dark:text-white"
        >
          <p>Connect your accounts first to see the dashboard</p>
          <RouterLink
            to="/team"
            class="px-4 py-2 bg-gray-800 border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest hover:bg-gray-700 focus:bg-gray-700 active:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50 transition ease-in-out duration-150 max-w-fit dark:bg-amber-500"
          >
            Go to Team Settings
          </RouterLink>
        </div>

        <ChartLayout v-else :team="currentTeam" />
      </div>
    </div>
  </div>
</template>
<script setup>
import ChartLayout from '@/layouts/ChartLayout.vue';
import NavBarLayout from '@/layouts/NavBarLayout.vue';

import { useTeamStore } from '@/stores/teamStore';
import { checkConnectorsMissing } from '@/utils/helperFunctions';
import { computed, ref, watch } from 'vue';
import { RouterLink } from 'vue-router';

const teamStore = useTeamStore();
const currentTeam = ref(teamStore.current_team);
watch(
  () => teamStore.current_team,
  (newTeam) => {
    currentTeam.value = newTeam;
  },
  {
    immediate: true,
  },
);
const shouldShowButton = computed(() => {
  if (currentTeam?.value?.id) {
    return checkConnectorsMissing(currentTeam.value);
  }
  return true;
});
</script>
