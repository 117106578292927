<script setup>
import { ref } from 'vue';
import SectionBorder from '@/components/SectionBorder.vue';
import NavBarLayout from '@/layouts/NavBarLayout.vue';
import PageHeading from '@/components/PageHeading.vue';
import ConnectorLayout from '@/layouts/ConnectorLayout.vue';
import BrandKeywordInput from '@/components/BrandKeywordInput.vue';
import { UserRoundCog } from 'lucide-vue-next';

const showKeywords = ref(true);

const toggleKeywords = () => {
  showKeywords.value = !showKeywords.value;
};
</script>

<template>
  <div class="flex">
    <NavBarLayout />
    <div
      class="flex-1 bg-white dark:bg-black dark:text-white min-h-screen mt-14 md:mt-0"
    >
      <PageHeading title="Team Settings" />
      <div class="max-w-full mx-2 py-2 sm:px-6 lg:px-8">
        <ConnectorLayout />

        <div class="px-4">
          <button
            @click="toggleKeywords"
            class="px-4 py-2 rounded-md font-semibold bg-gradient-to-r from-blue-400 to-indigo-700 hover:from-blue-500 hover:to-indigo-800 text-white transition-all flex gap-2"
          >
            <UserRoundCog />
            {{
              showKeywords
                ? 'Hide Brand Keywords Settings'
                : 'Show Brand Keywords Settings'
            }}
          </button>
        </div>

        <!-- Conditional display of BrandKeywordInput -->
        <div v-if="showKeywords" class="p-4">
          <BrandKeywordInput />
        </div>

        <!-- Other components can go here -->
      </div>
    </div>
  </div>
</template>
