<template>
  <div class="flex">
    <NavBarLayout />
    <div class="flex-1 dark:bg-black bg-gray-100 min-h-lvh mt-14 md:mt-0">
      <div v-if="name == 'Blinkit'">
        <BlinkitUploadCard />
      </div>
    </div>
  </div>
</template>
<script setup>
import BlinkitUploadCard from '@/components/BlinkitUploadCard.vue';
import NavBarLayout from '@/layouts/NavBarLayout.vue';
import { useRoute } from 'vue-router';
const route = useRoute();
const name = route.query.name;
</script>
