import { createRouter, createWebHistory } from 'vue-router';
import Login from '@/pages/Auth/Login.vue';
import ProfileShow from '@/pages/Profile/Show.vue';
import TeamsShow from '@/pages/Teams/Show.vue';
import TeamsCreate from '../pages/Teams/Create.vue';
import Register from '@/pages/Auth/Register.vue';
import WelcomeView from '@/views/WelcomeView.vue';
import VerifyEmailOtp from '@/pages/Auth/VerifyEmailOtp.vue';
import { useAuthStore } from '@/stores/authStore';
import DashBoard from '@/views/DashBoardView.vue';
import InsightView from '@/views/InsightView.vue';
import ForgotPassword from '@/pages/Auth/ForgotPassword.vue';
import NotFound from '@/views/NotFound.vue';
import GoogleAdsCallBackCard from '@/components/GoogleAdsCallBackCard.vue';
import GoogleAnalyticsCallBackCard from '@/components/GoogleAnalyticsCallBackCard.vue';
import GoogleSearchConsoleCallBackCard from '@/components/GoogleSearchConsoleCallBackCard.vue';
import FileConnectorView from '@/views/FileConnectorView.vue';

const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_BASE_URL),
  routes: [
    {
      path: '/',
      name: 'Home',
      component: WelcomeView,
      meta: {
        title: 'Home | DVIO ONE',
        requiresGuest: true,
      },
    },

    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: {
        title: 'Login | DVIO ONE',
        requiresGuest: true,
      },
    },
    {
      path: '/register',
      name: 'Register',
      component: Register,
      meta: {
        title: 'Register | DVIO ONE',
        requiresGuest: true,
      },
    },
    {
      path: '/verify',
      name: 'Verify',
      component: VerifyEmailOtp,
      meta: {
        title: 'Verify Yourself | DVIO ONE',
        requiresGuest: true,
      },
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: DashBoard,
      meta: { requiresAuth: true, title: 'Dashboard | DVIO ONE' },
    },
    {
      path: '/insight',
      name: 'Insight',
      component: InsightView,
      meta: { requiresAuth: true, title: 'Insight | DVIO ONE' },
    },
    {
      path: '/profile',
      name: 'Profile',
      component: ProfileShow,
      meta: { requiresAuth: true, title: 'User Profile | DVIO ONE' },
    },
    {
      path: '/team',
      name: 'Team',
      component: TeamsShow,
      meta: { requiresAuth: true, title: 'Team Settings | DVIO ONE' },
    },
    {
      path: '/create-team',
      name: 'Create Team',
      component: TeamsCreate,
      meta: { requiresAuth: true, title: 'Add New Team | DVIO ONE' },
    },
    {
      path: '/forgot-password',
      name: 'Forgot Password',
      component: ForgotPassword,
      meta: {
        title: 'Reset Password | DVIO ONE',
        requiresGuest: true,
      },
    },

    {
      path: '/connector/google-ads',
      name: 'Google Ads Connector',
      component: GoogleAdsCallBackCard,
      meta: {
        title: 'Google Ads Connector | DVIO ONE',
        requiresAuth: true,
      },
    },
    {
      path: '/connector/google-analytics-data-api',
      name: 'Google Analytics Connector',
      component: GoogleAnalyticsCallBackCard,
      meta: {
        title: 'Google Analytics Connector | DVIO ONE',
        requiresAuth: true,
      },
    },
    {
      path: '/connector/google-search-console',
      name: 'Google Search Console Connector',
      component: GoogleSearchConsoleCallBackCard,
      meta: {
        title: 'Google Search Console Connector | DVIO ONE',
        requiresAuth: true,
      },
    },
    {
      path: '/connector',
      name: 'Custom Connector',
      component: FileConnectorView,
      meta: {
        title: 'Custom Connector | DVIO ONE',
        requiresAuth: true,
      },
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'NotFound',
      component: NotFound,
      meta: {
        title: '404 Not Found | DVIO ONE',
      },
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();
  authStore.checkAuth();

  if (to.meta.requiresAuth && !authStore.isAuthenticated) {
    return next({ name: 'Login' });
  }

  if (to.meta.requiresGuest && authStore.isAuthenticated) {
    return next({ name: 'Dashboard' });
  }

  document.title = to.meta.title || 'DVIO ONE';
  next();
});

export default router;
