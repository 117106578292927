<template>
  <div class="p-4">
    <div>
      <div
        class="font-medium font-base md:text-lg pb-2 text-gray-800 dark:text-gray-300"
      >
        Connect your accounts
      </div>
      <div class="mb-4 flex items-center">
        <input
          v-model="searchQuery"
          type="text"
          placeholder="Search connectors..."
          class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400 dark:bg-gray-800 dark:text-white dark:border-gray-600"
        />
        <div class="relative ml-2">
          <span class="relative group">
            <Plus @click="addCustomConnector" class="cursor-pointer" />
            <span
              class="absolute hidden group-hover:block bg-gray-700 text-white text-xs rounded-md p-2 top-1/2 left-3/4 transform -translate-x-3/4 translate-y-3 mb-1 whitespace-nowrap"
            >
              Add Custom Connector
            </span>
          </span>
        </div>
      </div>

      <div v-if="filteredData.length === 0" class="text-center m-4 p-4">
        <p class="text-gray-600 dark:text-gray-300 mb-2">
          Can't find the right connector? Build your custom one.
        </p>
        <button
          @click="addCustomConnector"
          class="bg-gradient-to-r from-blue-500 to-indigo-600 hover:from-blue-600 hover:to-indigo-700 text-white font-semibold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out"
        >
          Add Custom Connector
        </button>
      </div>

      <div
        class="grid gap-4 md:gap-6 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1"
      >
        <div
          v-for="item in filteredData"
          :key="item.sourceType"
          class="p-2 h-full"
        >
          <ConnectorCard :data="item" v-if="!item.isFile" />
          <FileConnectorCard :data="item" v-if="item.isFile" />
        </div>
      </div>
      <SectionBorder />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue';
import ConnectorCard from '@/components/ConnectorCard.vue';
import FileConnectorCard from '@/components/FileConnectorCard.vue';
import { useConnectorStore } from '@/stores/connectorStore';
import { useTeamStore } from '@/stores/teamStore';
import axiosInstance from '@/utils/privateApiInstance';
import { Plus } from 'lucide-vue-next';
import { toast } from 'vue3-toastify';
import SectionBorder from '@/components/SectionBorder.vue';

const connectorsPropData = [
  { sourceType: 'google-ads', name: 'Google Ads', oauth: true, isFile: false },
  {
    sourceType: 'google-analytics-data-api',
    name: 'Google Analytics',
    oauth: true,
    isFile: false,
  },
  {
    sourceType: 'facebook-ads',
    name: 'Facebook Ads',
    oauth: false,
    isFile: false,
  },
  {
    sourceType: 'google-search-console',
    name: 'Google Search Console',
    oauth: true,
    isFile: false,
  },
  { sourceType: 'amazon-ads', name: 'Ads', oauth: false, isFile: false },
  { sourceType: 'blinkit', name: 'Blinkit', isFile: true },
];

const searchQuery = ref('');
const teamStore = useTeamStore();
const connectorStore = useConnectorStore();
const connections = ref(null);
const currentTeam = ref(teamStore.current_team);

const filteredData = computed(() => {
  return connectorsPropData.filter((item) =>
    item.sourceType.toLowerCase().includes(searchQuery.value.toLowerCase()),
  );
});

const fetchTeamConnectors = async () => {
  try {
    if (currentTeam.value?.id) {
      const { data } = await axiosInstance.get(
        `/team/${currentTeam.value.id}/connectors`,
      );
      connections.value = data;
    }
  } catch (error) {
    connections.value = [];
    console.error('Error fetching team connectors:', error);
  }
};

const updateConnectors = (newData) => {
  connectorStore.clearCompleted();
  connectorStore.clearId();
  if (newData) {
    connectorsPropData.forEach((item) => {
      const matchedConnection = newData.find(
        (connection) => connection.airbyte_source_type === item.sourceType,
      );
      if (matchedConnection) {
        connectorStore.setCompleted(item.sourceType, true);
        connectorStore.setConnectorId(
          item.sourceType,
          matchedConnection.airbyte_connection_id,
        );
      }
    });
  }
};

const addCustomConnector = () => {
  toast.info('This feature will soon be available');
};

watch(
  () => teamStore.current_team,
  (newData) => {
    currentTeam.value = newData;
    if (newData?.id) fetchTeamConnectors();
  },
  { immediate: true, deep: true },
);

watch(
  () => connections.value,
  (newData) => updateConnectors(newData),
  { deep: true, immediate: true },
);

onMounted(() => {
  if (currentTeam.value?.id) fetchTeamConnectors();
});
</script>

<style scoped>
.group:hover .tooltip {
  visibility: visible;
}
</style>
