<template>
  <div
    class="flex flex-col rounded-lg overflow-hidden py-2 px-2 md:px-4 bg-white dark:bg-gray-950 dark:text-white border dark:border-gray-700 min-h-full flex-grow"
  >
    <!-- Header section with title -->
    <div class="text-base md:text-lg">Total Users</div>
    <!-- ApexChart component with adjusted height -->
    <div
      class="flex-1 flex justify-center items-center flex-col lg:flex-row w-full"
    >
      <div class="lg:w-2/3">
        <apexchart
          type="donut"
          :options="chartOptions"
          :series="chartSeries"
        ></apexchart>
      </div>
      <div class="text-sm flex lg:flex-col flex-wrap lg:space-y-2">
        <span
          v-for="(item, index) in labelData"
          :key="index"
          :style="{ color: colors[index] }"
        >
          ● {{ item }}
        </span>
      </div>
    </div>
    <!-- Data source label -->
    <div class="text-left text-base">Google Analytics</div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import axios from '@/utils/privateApiInstance';
import { formatNumber } from '@/utils/helperFunctions';
import { useTeamStore } from '@/stores/teamStore';
import { useDateStore } from '@/stores/dateStore';

const data = ref();
const labelData = ref();
const chartLabels = ref([]);
const dateStore = useDateStore();
const teamStore = useTeamStore();
const currentTeam = ref(teamStore.current_team);

const colors = ['#fbbf24', '#f59e0b', '#34d399', '#60a5fa', '#e11d48'];
const chartOptions = computed(() => ({
  chart: {
    id: 'users-chart',
    type: 'donut',
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  colors: colors,
  dataLabels: {
    enabled: false,
  },
  legend: {
    show: false,
  },
  tooltip: {
    theme: 'dark',
  },
  noData: {
    text: 'Check Your Teams Connectors',
  },
  labels: chartLabels.value,
}));

const chartSeries = ref([0, 0]);

const fetchData = async () => {
  try {
    const response = await axios.get(
      `/google-analytics/${currentTeam.value.id}/first-user-source-metrics`,
      {
        params: {
          start_date: dateStore?.dashboardDate?.currentStart,
          end_date: dateStore?.dashboardDate?.currentEnd,
        },
      },
    );
    data.value = JSON.parse(response.data?.items.data);
  } catch (error) {
    data.value = [];
    labelData.value = [];
    chartLabels.value = [];
    chartSeries.value = [];
  }
};
watch(
  [dateStore.dashboardDate],
  async () => {
    await fetchData();
  },
  { immediate: true, deep: true },
);

watch(
  () => data?.value,
  (newData) => {
    if (Array.isArray(newData)) {
      const labels = newData.map((item) => item[0]);
      const series = newData.map((item) => item[1]);

      labelData.value = newData.map(
        (item) =>
          item[0].toUpperCase().slice(0, 8) + '-' + formatNumber(item[1]),
      );

      chartLabels.value = labels;
      chartSeries.value = series;
    }
  },
  { immediate: true },
);
watch(
  () => teamStore.current_team,
  (newTeam) => {
    currentTeam.value = newTeam;
    if (newTeam.id) {
      fetchData();
    }
  },
  {
    immediate: true,
    deep: true,
  },
);
</script>
