<template>
  <div class="flex">
    <NavBarLayout />
    <div class="flex-1 dark:bg-black min-h-screen bg-gray-100 mt-14 md:mt-0">
      <div
        class="rounded-lg p-6 md:p-10 w-full h-full flex flex-col justify-center items-center shadow-lg space-y-6"
      >
        <div
          v-if="loading"
          class="bg flex flex-col justify-center items-center h-full w-full"
        >
          <div class="animate-spin"><Settings :size="40" /></div>

          <div class="text-lg font-semibold font-mono">
            Connecting your account
          </div>
        </div>
        <div v-else>
          <div>
            <!-- Header with Google Search Console Logo -->
            <div class="flex items-center justify-center mb-6">
              <img
                src="@/assets/google-search-console.png"
                alt="Google Search Console Logo"
                class="h-8 w-8 mr-3"
              />
              <div
                v-if="connected"
                class="font-medium text-gray-800 dark:text-gray-100text-center lg:text-lg text-base h-full w-full p-4"
              >
                Connected Successfully . Navigating you back .....
              </div>
              <div
                class="text-2xl font-bold text-gray-800 dark:text-gray-100"
                v-else
              >
                Choose Your Websites to Sync
              </div>
            </div>
            <div v-if="!connected">
              <!-- Instruction Text -->
              <p class="text-gray-600 dark:text-gray-300 text-center">
                Enter one or more website URLs below to sync with Google Search
                Console. You can add and remove websites as needed, and click
                "Connect Selected Websites" to proceed.
              </p>

              <!-- Input Fields for URLs -->
              <div
                v-for="(url, index) in siteUrls"
                :key="index"
                class="flex items-center mb-4 space-x-3 w-full"
              >
                <input
                  v-model="siteUrls[index]"
                  type="text"
                  placeholder="Enter website URL"
                  required
                  class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400 dark:bg-gray-800 dark:text-white dark:border-gray-600"
                />
                <button
                  @click="removeSiteUrl(index)"
                  class="text-red-500 hover:text-red-700 font-semibold transition duration-200 ease-in-out"
                >
                  <CircleMinus />
                </button>
              </div>

              <!-- Add More Website Button -->
              <div class="flex justify-center mb-4">
                <button
                  @click="addNewSiteUrl"
                  class="text-blue-600 font-semibold hover:underline transition duration-200 ease-in-out flex gap-2"
                >
                  <CirclePlus /> Add website
                </button>
              </div>

              <!-- Connect Button -->
              <div class="flex justify-end mt-8">
                <button
                  v-if="secretId"
                  @click="establishConnection"
                  :disabled="siteUrls.length === 0"
                  class="bg-gradient-to-r from-blue-500 to-indigo-600 hover:from-blue-600 hover:to-indigo-700 text-white font-semibold py-3 px-8 rounded-full shadow-lg transition duration-300 ease-in-out disabled:opacity-50"
                >
                  Connect Selected Websites
                </button>
                <button
                  v-else
                  @click="continueConection"
                  :disabled="siteUrls.length === 0"
                  class="bg-gradient-to-r from-blue-500 to-indigo-600 hover:from-blue-600 hover:to-indigo-700 text-white font-semibold py-3 px-8 rounded-full shadow-lg transition duration-300 ease-in-out"
                >
                  Continue Selecting Sites
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import NavBarLayout from '@/layouts/NavBarLayout.vue';
import { useTeamStore } from '@/stores/teamStore';
import axiosInstance from '@/utils/privateApiInstance';
import { CircleMinus, CirclePlus, Settings } from 'lucide-vue-next';
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { toast } from 'vue3-toastify';

const secretId = ref();
const sourceType = 'google-search-console';
const route = useRoute();
const router = useRouter();
const loading = ref(false);
const teamStore = useTeamStore();
const currentTeam = ref(teamStore.current_team);
const siteUrls = ref([]);
const connected = ref(false);
watch(
  () => teamStore.current_team,
  (newTeam) => {
    currentTeam.value = newTeam;
  },
  {
    immediate: true,
    deep: true,
  },
);

const addNewSiteUrl = () => {
  siteUrls.value.push('');
};

const removeSiteUrl = (index) => {
  siteUrls.value.splice(index, 1);
};
const urlPattern = new RegExp(/^.+\..+$/);

const isValidUrl = (url) => {
  return urlPattern.test(url);
};

const allValidUrls = computed(() => {
  return siteUrls.value.every((url) => isValidUrl(url) || url === '');
});
const establishConnection = async () => {
  try {
    if (!allValidUrls.value || siteUrls.value.length === 0) {
      if (siteUrls.value.length >= 2) {
        toast.error('Please enter valid urls');
      } else {
        toast.error('Please enter a valid url');
      }
      return;
    }

    loading.value = true;
    const response = await axiosInstance.post(
      `/airbyte/${currentTeam.value.id}/google-search-console`,
      {
        secret_id: secretId.value,
        site_url: siteUrls.value,
      },
      {
        params: {
          source_type: sourceType,
        },
      },
    );
    if (response.status == 200) {
      toast.success('Account Connected successfully', {
        onClose: () => {
          router.push('/team');
        },
      });
      connected.value = true;
    }
  } catch (error) {
    toast.error(error.response.data.detail);
  } finally {
    loading.value = false;
  }
};
const continueConection = async () => {
  try {
    if (!allValidUrls.value || siteUrls.value.length === 0) {
      if (siteUrls.value.length >= 2) {
        toast.error('Please enter valid urls');
      } else {
        toast.error('Please enter a valid url');
      }
      return;
    }

    loading.value = true;
    const response = await axiosInstance.patch(
      `/airbyte/${currentTeam.value.id}/google-search-console`,
      {
        site_url: siteUrls.value,
      },
      {
        params: {
          source_type: sourceType,
        },
      },
    );
    if (response.status == 200) {
      toast.success('Account Connected successfully', {
        onClose: () => {
          router.push('/team');
        },
      });
      connected.value = true;
    }
  } catch (error) {
    toast.error(error.response.data.detail);
  } finally {
    loading.value = false;
  }
};
onMounted(() => {
  if (route.query.secret_id) {
    toast.success('Account Authorized successfully!');
    secretId.value = route.query.secret_id;
  }
});
</script>
