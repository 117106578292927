<template>
  <div
    class="flex flex-col rounded-lg overflow-hidden py-4 text-center px-2 md:px-4 bg-white dark:bg-gray-950 dark:text-white border dark:border-gray-700 h-full min-h-[280px]"
  >
    <div>
      <div class="flex justify-between w-full">
        <div class="text-base lg:text-xl font-medium">Average Position</div>
        <div
          class="flex text-sm items-baseline text-gray-500 dark:text-gray-300 gap-2"
        >
          <Calendar :size="16" />
          <span>{{ formattedStartDate }} - {{ formattedEndDate }}</span>
        </div>
      </div>
    </div>
    <div
      v-if="!loading"
      class="flex w-full justify-center flex-1 items-center gap-2 md:gap-4"
    >
      <div class="text-xl lg:text-3xl font-medium font-roboto">
        {{ growth }}
      </div>
      <div
        v-if="change < 0"
        class="flex items-center text-green-500 text-lg lg:text-xl"
      >
        <ArrowUp :size="18" />{{ change?.toFixed(1) }}%
      </div>
      <div v-else class="flex items-center text-red-500 text-sm lg:text-base">
        <ArrowDown :size="18" />{{ change?.toFixed(1) }}%
      </div>
    </div>
    <div v-if="loading" class="flex justify-center items-center p-2 flex-1">
      <StyledLoader />
    </div>

    <!-- Data source label -->
    <div class="flex gap-2 pt-1 text-xs font-medium">
      <img
        src="@/assets/google-search-console.png"
        alt="Google Search Console"
        class="h-3 w-auto"
      />Google Search Console
    </div>
  </div>
</template>
<script setup>
import { useTeamStore } from '@/stores/teamStore';
import { useDateStore } from '@/stores/dateStore';
import axios from '@/utils/privateApiInstance';
import { ref, watch, nextTick, computed } from 'vue';
import { ArrowDown, ArrowUp, Calendar } from 'lucide-vue-next';
import StyledLoader from '../StyledLoader.vue';

const teamStore = useTeamStore();
const dateStore = useDateStore();
const currentTeam = ref(teamStore.current_team);
const change = ref();
const loading = ref(false);

const growth = ref('');

const formatData = (data) => data.toFixed(2);
const fetchData = async () => {
  try {
    loading.value = true;
    const response = await axios.get(
      `/google-search-console-insights/${currentTeam.value.id}/average-positions`,
      {
        params: {
          start_date: dateStore?.insightDate?.currentStart,
          end_date: dateStore?.insightDate?.currentEnd,
          past_start_date: dateStore?.insightDate?.pastStart,
          past_end_date: dateStore?.insightDate?.pastEnd,
        },
      },
    );

    const data = response.data?.items.data;

    if (data) {
      growth.value = formatData(data[0]);
      change.value = data[2];
    }
  } catch (error) {
    growth.value = 'Try Again';
    change.value = 0;
  } finally {
    loading.value = false;
  }
};
watch(
  () => dateStore.insightDate,
  async () => {
    await nextTick();
    await fetchData();
  },
  { immediate: true, deep: true },
);
watch(
  () => teamStore.current_team,
  (newTeam) => {
    currentTeam.value = newTeam;
    if (newTeam?.id) {
      fetchData();
    }
  },
  {
    immediate: true,
    deep: true,
  },
);
const formattedStartDate = computed(() => {
  const options = { year: 'numeric', month: 'short' };
  return new Date(dateStore?.insightDate?.pastStart).toLocaleDateString(
    'en-US',
    options,
  );
});

const formattedEndDate = computed(() => {
  const options = { year: 'numeric', month: 'short', year: 'numeric' };
  return new Date(dateStore?.insightDate?.currentEnd).toLocaleDateString(
    'en-US',
    options,
  );
});
</script>
