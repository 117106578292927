<template>
  <div class="relative" ref="dropdownContainer">
    <div @click="toggleDropdown">
      <slot name="trigger" />
    </div>
    <transition name="fade">
      <div
        v-if="isOpen"
        class="z-50 absolute w-full bottom-full bg-white border border-gray-200 dark:bg-gray-950 dark:border-gray-600"
      >
        <slot name="content" />
      </div>
    </transition>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';

const isOpen = ref(false);
const dropdownContainer = ref(null);

const toggleDropdown = () => {
  isOpen.value = !isOpen.value;
};

const handleClickOutside = (event) => {
  if (
    dropdownContainer.value &&
    !dropdownContainer.value.contains(event.target)
  ) {
    isOpen.value = false;
  }
};

onMounted(() => {
  document.addEventListener('click', handleClickOutside);
});

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside);
});
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

/* Ensure that the dropdown opens above */
.dropdown-menu {
  bottom: 100%;
  margin-bottom: 0.5rem;
}
</style>
