import axios from 'axios';
import { toast } from 'vue3-toastify';

const url = import.meta.env.VITE_BACKEND_URL;

const axiosInstance = axios.create({
  baseURL: `${url}/api`,
});

let isRefreshing = false;
const failedQueue = [];
let toastShown = false;

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (token) {
      prom.resolve(token);
    } else {
      prom.reject(error);
    }
  });
  failedQueue.length = 0;
};

const refreshAccessToken = async () => {
  const appRefreshToken = localStorage.getItem('refresh_token');

  if (!appRefreshToken) {
    throw new Error('No refresh token available');
  }

  try {
    const response = await axios.post(`${url}/api/auth/refresh`, null, {
      headers: { 'refresh-token': appRefreshToken },
    });

    const { access_token: newAccessToken, refresh_token: newRefreshToken } =
      response.data;

    localStorage.setItem('access_token', newAccessToken);
    localStorage.setItem('refresh_token', newRefreshToken);

    return newAccessToken;
  } catch (error) {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    throw error;
  }
};

axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('access_token');
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;

      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers.Authorization = `Bearer ${token}`;
            return axiosInstance(originalRequest);
          })
          .catch((err) => Promise.reject(err));
      }

      isRefreshing = true;

      try {
        const newAccessToken = await refreshAccessToken();

        axiosInstance.defaults.headers.common['Authorization'] =
          `Bearer ${newAccessToken}`;

        processQueue(null, newAccessToken);

        isRefreshing = false;

        toastShown = false;

        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        processQueue(refreshError, null);
        isRefreshing = false;

        if (!toastShown) {
          toast.error('Your session has expired. Please log in again.', {
            autoClose: 2000,
            onClose: () => {
              window.location.href = '/';
            },
          });
          toastShown = true;
        }

        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  },
);

export default axiosInstance;
