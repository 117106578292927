<script setup>
import { computed, ref, watch } from 'vue';
import ClickDateGADS from '@/components/g_ads_metrics/ClickDateGADS.vue';
import ConversionDateGADS from '@/components/g_ads_metrics/ConversionDateGADS.vue';
import CPCDateGADS from '@/components/g_ads_metrics/CPCDateGADS.vue';
import CRDateGADS from '@/components/g_ads_metrics/CRDateGADS.vue';
import CTRDateGADS from '@/components/g_ads_metrics/CTRDateGADS.vue';
import ImpDateGADS from '@/components/g_ads_metrics/ImpDateGADS.vue';
import TotalSpentDateGADS from '@/components/g_ads_metrics/TotalSpentDateGADS.vue';
import CPCVsCTRGADS from '@/components/charts/CPCVsCTRGADS.vue';
import ImpressionAndClick from '@/components/charts/ImpressionAndClick.vue';
import ClicksDateGSC from '@/components/g_search_console_metrics/ClicksDateGSC.vue';
import CTRDateMatrixGSC from '@/components/g_search_console_metrics/CTRDateMatrixGSC.vue';
import ImpDateGSC from '@/components/g_search_console_metrics/ImpDateGSC.vue';
import SessionTypeDonut from '@/components/charts/SessionTypeDonut.vue';
import EngagedSessionDateGSTAT from '@/components/g_stats_metrics/EngagedSessionDateGSTAT.vue';
import NewUserDateGSTAT from '@/components/g_stats_metrics/NewUserDateGSTAT.vue';
import SessionDateGSTAT from '@/components/g_stats_metrics/SessionDate.GSTAT.vue';
import TotalUserDateGSTAT from '@/components/g_stats_metrics/TotalUserDateGSTAT.vue';
import UserMatrix from '@/components/charts/UserMatrix.vue';
import DatePicker from '@/components/DatePicker.vue';
import { toast } from 'vue3-toastify';
import { Sparkles } from 'lucide-vue-next';
import { useDateStore } from '@/stores/dateStore';
const props = defineProps({
  team: {
    required: true,
  },
});

const isModalOpen = ref(false);
const isDataVisible = ref(false);
const isAnimating = ref(false);
const stopFetching = ref(false);
const apiData = ref('');
const dateStore = useDateStore();
const startDate = ref(dateStore.dashboardDate.currentStart);
const endDate = ref(dateStore.dashboardDate.currentEnd);

watch(
  dateStore.dashboardDate,
  () => {
    startDate.value = dateStore.dashboardDate.currentStart;
    endDate.value = dateStore.dashboardDate.currentEnd;
  },
  {
    immediate: true,
    deep: true,
  },
);
let abortController;

const toggleData = () => {
  if (isDataVisible.value) {
    isDataVisible.value = false;
    stopFetching.value = true;
    if (abortController) {
      abortController.abort();
    }
    apiData.value = '';
  } else {
    isDataVisible.value = true;
    stopFetching.value = false;
    fetchData();
  }
};

const backendUrl = import.meta.env.VITE_BACKEND_URL;
const accessToken = localStorage.getItem('access_token');

const fetchData = async () => {
  const eventUrl = `${backendUrl}/api/ai/${props.team.id}/insights-summarization?start_date=${startDate.value}&end_date=${endDate.value}`;

  abortController = new AbortController();
  const { signal } = abortController;

  try {
    isAnimating.value = true;
    const response = await fetch(eventUrl, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'text/stream',
      },
      signal,
    });

    if (!response.ok) {
      toast.error('Failed to get insights');
      return;
    }

    const reader = response.body.getReader();
    const decoder = new TextDecoder();
    let result = '';

    const processStream = async () => {
      const { done, value } = await reader.read();
      if (done || stopFetching.value) {
        apiData.value = result;
        isAnimating.value = false;
        return;
      }

      result += decoder.decode(value, { stream: true });
      apiData.value = result;

      if (apiData.value.length > 0 && isAnimating.value) {
        isAnimating.value = false;
      }

      processStream();
    };

    processStream();
  } catch (error) {
    console.error('Fetch error:', error);
    isAnimating.value = false;
  }
};
const dayDifference = computed(() => {
  return Math.ceil(
    (new Date(endDate.value) - new Date(startDate.value)) /
      (1000 * 60 * 60 * 24),
  );
});
const formattedStartDate = computed(() => {
  const options = { day: 'numeric', month: 'short' };
  return new Date(startDate.value).toLocaleDateString('en-US', options);
});

const formattedEndDate = computed(() => {
  const options = { day: 'numeric', month: 'short', year: 'numeric' };
  return new Date(endDate.value).toLocaleDateString('en-US', options);
});

const openModal = () => {
  isModalOpen.value = true;
};

const closeModal = () => {
  isModalOpen.value = false;
};
</script>

<template>
  <div class="flex justify-between px-3 pt-4 items-center flex-col sm:flex-row">
    <div class="text-xl sm:text-2xl font-semibold dark:text-white">
      {{ props.team?.name }}
    </div>
    <div class="flex flex-col md:flex-row gap-3 w-full sm:w-auto">
      <div
        @click="toggleData"
        class="flex gap-2 border rounded-md p-2 cursor-pointer text-white bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-xs sm:text-sm justify-center items-center flex-wrap"
      >
        <Sparkles :size="16" class="sm:self-center" />Explain this to me
      </div>
      <div
        @click="openModal"
        class="flex items-center p-2 rounded-lg bg-gray-200 dark:bg-gray-800 shadow-md space-x-4 cursor-pointer justify-around"
      >
        <span
          class="text-xs font-semibold text-gray-600 dark:text-gray-300 bg-gray-300 dark:bg-gray-700 px-2 py-1 rounded"
        >
          {{ dayDifference }} days
        </span>
        <div class="text-sm font-semibold dark:text-white">
          {{ formattedStartDate }} - {{ formattedEndDate }}
        </div>

        <svg
          :class="{ 'rotate-180': isModalOpen }"
          class="transition-transform"
          width="16"
          height="16"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19 9l-7 7-7-7"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
  </div>

  <!-- DatePicker Component -->
  <DatePicker
    :compareRequire="false"
    :isModalOpen="isModalOpen"
    @close="closeModal"
  />

  <div
    v-if="isDataVisible"
    class="p-4 rounded-md scroll-custom bg-white dark:bg-gray-950 m-3"
  >
    <h3
      class="text-base md:text-lg font-bold mb-3 dark:text-gray-100 text-gray-900"
    >
      Insight:
    </h3>
    <div
      class="overflow-auto max-h-80 p-3 bg-gradient-to-r from-gray-100 to-gray-200 dark:bg-gradient-to-r dark:from-gray-800 dark:to-gray-900 rounded"
    >
      <div v-if="isAnimating" class="ai-loader">
        <div
          class="animate-bounce text-transparent font-semibold bg-clip-text bg-gradient-to-r from-indigo-600 via-purple-600 to-pink-600 text-5xl"
        >
          ✨
        </div>
      </div>
      <pre
        v-else
        class="text-base text-gray-800 dark:text-gray-200 whitespace-pre-wrap font-roboto"
        >{{ apiData }}
    </pre
      >
    </div>
  </div>
  <div
    class="bg-gray-100 dark:bg-black p-3 text-base dark:text-white font-semibold grid grid-cols-1 md:grid-cols-2 gap-5 h-full"
  >
    <div class="flex flex-col">
      <h2 class="text-lg font-bold mb-3">ADS PERFORMANCE METRICS</h2>
      <div class="flex-1 grid grid-cols-3 grid-rows-5 gap-3">
        <div>
          <TotalSpentDateGADS />
        </div>
        <div>
          <ConversionDateGADS />
        </div>
        <div><CRDateGADS /></div>
        <div><CPCDateGADS /></div>
        <div class="col-start-1 row-start-3">
          <CTRDateGADS />
        </div>
        <div class="col-span-2 row-span-2 col-start-2 row-start-2">
          <CPCVsCTRGADS />
        </div>
        <div class="col-span-2 row-span-2 row-start-4">
          <ImpressionAndClick />
        </div>
        <div class="col-start-3 row-start-4">
          <ImpDateGADS />
        </div>
        <div class="col-start-3 row-start-5">
          <ClickDateGADS />
        </div>
      </div>
    </div>

    <div class="flex flex-col">
      <h2 class="text-lg font-bold mb-3">ORGANIC METRICS</h2>
      <div class="flex-1 grid grid-cols-3 grid-rows-5 gap-3">
        <div><ImpDateGSC /></div>
        <div><ClicksDateGSC /></div>
        <div><CTRDateMatrixGSC /></div>
        <div><SessionDateGSTAT /></div>
        <div class="col-start-1 row-start-3">
          <EngagedSessionDateGSTAT />
        </div>
        <div class="col-span-2 row-span-2 col-start-2 row-start-2">
          <SessionTypeDonut />
        </div>
        <div class="col-span-2 row-span-2 row-start-4">
          <UserMatrix />
        </div>
        <div class="col-start-3 row-start-4">
          <NewUserDateGSTAT />
        </div>
        <div class="col-start-3 row-start-5">
          <TotalUserDateGSTAT />
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
/* Style for the date input fields */
input[type='date'] {
  padding: 0.5rem; /* Reduced padding */
  height: 2rem; /* Reduced height */
  width: 8rem; /* Reduced width */
  border-radius: 0.375rem; /* Adjust border-radius if needed */
}

/* Ensuring the date input icon is visible in dark theme */
.dark input[type='date']::-webkit-calendar-picker-indicator {
  filter: invert(
    1
  ); /* Invert the icon color to be visible on dark background */
  width: 1rem; /* Adjust the width of the calendar icon */
  height: 1rem; /* Adjust the height of the calendar icon */
}

/* Firefox specific styling */
.dark input[type='date']::-moz-calendar-picker-indicator {
  filter: invert(1); /* Firefox specific styling */
  width: 1rem; /* Adjust the width of the calendar icon */
  height: 1rem; /* Adjust the height of the calendar icon */
}

/* Edge specific styling */
.dark input[type='date']::-ms-calendar-picker-indicator {
  filter: invert(1); /* Edge specific styling */
  width: 1rem; /* Adjust the width of the calendar icon */
  height: 1rem; /* Adjust the height of the calendar icon */
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: gray;
}
</style>
