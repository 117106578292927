<template>
  <div
    class="flex flex-col items-center transition-all duration-300 w-full h-full p-2"
  >
    <!-- Header Section -->
    <div
      class="dark:text-white p-4 w-full text-base sm:text-lg md:text-2xl font-semibold font-roboto flex justify-start items-center"
    >
      <div
        class="flex gap-2 p-2 justify-start items-center self-start bg-blue-500 hover:bg-blue-600 dark:bg-blue-400 dark:hover:bg-blue-500 text-white text-sm font-medium rounded-md cursor-pointer"
        @click.prevent="goBackToTeam"
      >
        <div class="rotate-90"><ChevronsDown /></div>
        Go Back
      </div>
      <div class="flex-1 text-center">{{ name }} Connector</div>
      <div
        class="flex gap-2 p-2 justify-start items-center self-start bg-slate-600 hover:bg-slate-700 dark:bg-slate-300 dark:hover:bg-slate-400 text-white dark:text-black text-sm font-medium rounded-md cursor-pointer"
        @click="showLogs = !showLogs"
      >
        <History size="18" />History
      </div>
    </div>
    <div class="w-full px-4" v-if="showLogs">
      <LastUploadCard :data />
    </div>
    <div class="w-full p-4">
      <div
        class="text-md md:text-lg lg:text-xl font-semibold text-gray-800 dark:text-gray-200 py-2"
      >
        Select Sheet Type:
      </div>
      <div class="grid grid-cols-1 md:grid-cols-3 gap-4 w-full">
        <!-- Loop to show cards -->
        <div
          v-for="card in cards"
          :key="card.title"
          class="relative bg-white dark:bg-gray-800 shadow-md rounded-lg p-6 cursor-pointer hover:shadow-lg transition-shadow duration-300 border border-gray-200 dark:border-gray-700"
          @click="selectCard(card.type)"
        >
          <!-- Top Accent Border -->
          <div
            class="absolute top-0 left-0 right-0 h-1 rounded-t-lg bg-blue-500 dark:bg-blue-400"
          ></div>

          <!-- Card Content -->
          <div class="relative z-10">
            <!-- Title and Tick Mark -->
            <div class="flex justify-between items-center mb-3">
              <div
                class="text-lg font-semibold text-gray-900 dark:text-gray-100"
              >
                {{ card.title }}
              </div>
              <div v-if="selectedCardType === card.type">
                <CheckCircle class="text-green-500" />
              </div>
            </div>

            <!-- Description -->
            <div
              class="text-gray-700 dark:text-gray-300 text-sm leading-relaxed"
            >
              {{ card.description }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-full h-full overflow-hidden" v-if="selectedCardType">
      <!-- Instructions Section -->
      <div class="p-4">
        <div
          class="text-md md:text-lg lg:text-xl font-semibold text-gray-800 dark:text-gray-200"
        >
          Guidelines Before Uploading :
        </div>
        <div class="flex flex-col md:flex-row gap-4 justify-between mx-2">
          <div class="mt-2 text-gray-600 dark:text-gray-400 flex-1">
            Make sure your file contains the following columns:
          </div>
          <!-- Template Download Link -->
          <a
            href="/BLINKIT_TEMPLATE.csv"
            download
            class="flex items-center px-4 py-2 bg-blue-500 hover:bg-blue-600 dark:bg-blue-400 dark:hover:bg-blue-500 text-white text-sm font-medium rounded-md transition-colors gap-2"
          >
            <CloudDownload />
            Download Template
          </a>
        </div>

        <!-- Enhanced Scrollable Box for Required Columns -->
        <div
          class="mt-6 border border-gray-300 dark:border-gray-700 rounded-lg p-4 bg-gray-100 dark:bg-gray-900 shadow-lg max-h-60 overflow-y-auto custom-scrollbar"
        >
          <ul class="list-none gap-2 grid md:grid-cols-3">
            <li
              v-for="(column, index) in selectedColumns"
              :key="index"
              class="flex items-center p-2 rounded-lg bg-gray-200 dark:bg-gray-800 hover:bg-gray-300 dark:hover:bg-gray-700 text-sm"
            >
              <span class="mr-2 text-blue-600 dark:text-blue-400">
                <ClipboardCheck :size="16" />
              </span>
              <span class="text-gray-800 dark:text-gray-300">
                {{ column }}
              </span>
            </li>
          </ul>
        </div>
      </div>

      <!-- Drop File Section -->
      <div class="p-4 h-full w-full">
        <DropFile :data="selectedCardType" />
      </div>
    </div>
  </div>
</template>

<script setup>
import DropFile from '@/components/DropFile.vue';
import LastUploadCard from '@/components/LastUploadCard.vue';
import { blinkitColumns } from '@/utils/constantData';
import {
  CloudDownload,
  ClipboardCheck,
  ChevronsDown,
  History,
  CheckCircle,
} from 'lucide-vue-next';
import { ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const router = useRouter();
const route = useRoute();
const name = route.query.name;
const sourceType = name.toLowerCase();
const data = { sourceType };

const showLogs = ref(true);
const cards = ref([
  {
    title: 'Product Listing',
    description: 'Upload CSV for Product Listings',
    type: 'product-listing',
  },
  {
    title: 'Product Recommendation',
    description: 'Upload CSV for Product Recommendations',
    type: 'product-recommendation',
  },
  {
    title: 'Banner Listing',
    description: 'Upload CSV for Banner Listings',
    type: 'banner-listing',
  },
]);

const selectedCardType = ref();

const selectCard = (type) => {
  selectedCardType.value = type;
};
const goBackToTeam = () => {
  router.push('/team');
};
const selectedColumns = computed(() => {
  switch (selectedCardType.value) {
    case 'product-listing':
      return blinkitColumns.product_listing;
    case 'product-recommendation':
      return blinkitColumns.product_recommendation;
    case 'banner-listing':
      return blinkitColumns.banner_listing;
    default:
      return [];
  }
});
</script>

<style scoped>
.custom-scrollbar::-webkit-scrollbar {
  width: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #3b82f6;
  border-radius: 6px 0 6px 0;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #2563eb;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.dark .custom-scrollbar::-webkit-scrollbar-track {
  background-color: #1f2937;
}
</style>
