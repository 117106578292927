<template>
  <div
    class="flex flex-col rounded-lg border bg-white dark:bg-gray-950 dark:text-white overflow-hidden p-2 dark:border-gray-700 cursor-default hover:scale-105 transition-transform w-full h-full"
  >
    <!-- Header section with title -->
    <div class="text-xs font-normal text-left mb-2 flex items-center gap-1">
      <img
        src="../assets/google-ads.png"
        alt="Google Ads"
        class="h-6 w-6 p-1"
        v-if="srcName == 'Google Ads'"
      />
      <img
        src="../assets/google_analytics_4.png"
        alt="Google Ads"
        class="h-6 w-6 p-1"
        v-if="srcName == 'Google Analytics'"
      />
      <img
        src="../assets/google-search-console.png"
        alt="Google Ads"
        class="h-6 w-6 p-1"
        v-if="srcName == 'Google Search Console'"
      />
      <span class="font-semibold">{{ srcName }}</span>
    </div>

    <!-- Growth value display -->
    <div
      class="flex-grow flex items-center justify-center text-lg lg:text-2xl font-medium"
    >
      <span v-if="!loading" class="text-center"
        >{{ growth }}
        <p class="text-xs lg:text-sm font-normal">
          {{ name.toLocaleUpperCase() }}
        </p>
      </span>
      <StyledLoader v-else />
    </div>
  </div>
</template>

<script setup>
import { ref, watch, nextTick } from 'vue';
import axios from '../utils/privateApiInstance';
import StyledLoader from './StyledLoader.vue';
import { useTeamStore } from '@/stores/teamStore';
import { useDateStore } from '@/stores/dateStore';

const dateStore = useDateStore();
const teamStore = useTeamStore();
const currentTeam = ref(teamStore.current_team);

const props = defineProps({
  apiEndpoint: {
    type: String,
    required: true,
  },
  metricName: {
    type: String,
    required: true,
  },
  formatData: {
    type: Function,
    default: (data) => data,
  },
  srcName: {
    type: String,
    default: 'Google Ads',
  },
});

const name = props.metricName;
const growth = ref('');
const loading = ref(false);

const fetchData = async () => {
  loading.value = true;
  try {
    const firstPart = props.apiEndpoint.split('/')[1];
    const secondPart = props.apiEndpoint.split('/')[2];
    const url = `/${firstPart}/${currentTeam.value.id}/${secondPart}`;

    const response = await axios.get(url, {
      params: {
        start_date: dateStore?.dashboardDate?.currentStart,
        end_date: dateStore?.dashboardDate?.currentEnd,
      },
    });
    const data = response.data?.items;

    if (data?.data) {
      growth.value = props.formatData(data);
    } else {
      growth.value = 'Try Again';
    }
  } catch (error) {
    growth.value = 'Try Again';
  } finally {
    loading.value = false;
  }
};
watch(
  () => teamStore.current_team,
  (newTeam) => {
    currentTeam.value = newTeam;
    if (newTeam.id) {
      fetchData();
    }
  },
  {
    immediate: true,
    deep: true,
  },
);
watch(
  () => dateStore.dashboardDate,
  async () => {
    await nextTick();
    await fetchData();
  },
  { immediate: true, deep: true },
);
</script>
