export function formatNumber(num) {
  if (num >= 1_000_000) {
    return (num / 1_000_000).toFixed(1).replace(/\.0$/, '') + 'M';
  } else if (num >= 1_000) {
    return (num / 1_000).toFixed(1).replace(/\.0$/, '') + 'K';
  } else if (num < 1_000 && num > 0) {
    return (num / 1_000).toFixed(1).replace(/\.0$/, '') + 'K';
  } else {
    return num?.toString();
  }
}
export function calculateDate(days) {
  const date = new Date();
  date.setDate(date.getDate() + days);
  return date.toISOString().split('T')[0];
}
export function calculateDateFrom(baseDate, offsetDays) {
  const date = new Date(baseDate);
  date.setDate(date.getDate() + offsetDays);
  return date.toISOString().split('T')[0];
}
export function getDateDifference(startDate, endDate) {
  const start = new Date(startDate);
  const end = new Date(endDate);
  return Math.floor((end - start) / (1000 * 60 * 60 * 24));
}
export function goBack() {
  history.back();
}

export function checkConnectorsMissing(currentTeam) {
  if (!currentTeam) {
    return true;
  }

  const connectorsEmpty = currentTeam.connection_details.length === 0;
  const anyConnectorHasConnection = currentTeam.connection_details.some(
    (connector) => connector.airbyte_connection_id != '',
  );

  if (connectorsEmpty) {
    return true;
  }

  return !anyConnectorHasConnection;
}
