<template>
  <AuthenticationCard>
    <template #logo>
      <AuthenticationCardLogo />
    </template>

    <div class="mb-4 text-xl font-bold text-black">
      Verify your email address using the OTP.
    </div>

    <!-- Email Input Field with Label -->
    <div class="mb-4">
      <label for="emailInput" class="block text-sm font-medium text-gray-700"
        >Email Address</label
      >
      <input
        id="emailInput"
        v-model="emailInput"
        type="email"
        :disabled="isEmailFieldDisabled"
        class="border border-gray-300 bg-gray-100 text-black rounded-md p-1 w-full text-center text-lg focus:outline-none focus:ring-2 focus:ring-blue-500 mt-1"
        placeholder="Enter your email"
        required
      />
      <p v-if="emailError" class="text-red-700 text-xs p-1">
        *{{ emailError }}
      </p>
    </div>

    <form @submit.prevent="submitOtp">
      <!-- OTP Inputs with Label -->
      <OtpInput
        :otpcode="otpcode"
        :error="error"
        :errorMessage="errorMessage"
        @update:otpcode="otpcode = $event"
        @update:error="error = $event"
        @update:errorMessage="errorMessage = $event"
      />

      <!-- Submit Button -->
      <div>
        <PrimaryButton
          :class="{ 'opacity-25': formProcessing }"
          :disabled="formProcessing"
        >
          Verify OTP
        </PrimaryButton>
      </div>
    </form>
  </AuthenticationCard>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import { useRouter } from 'vue-router';
import { toast } from 'vue3-toastify';
import AuthenticationCard from '@/components/AuthenticationCard.vue';
import AuthenticationCardLogo from '@/components/AuthenticationCardLogo.vue';
import PrimaryButton from '@/components/PrimaryButton.vue';
import OtpInput from '@/components/OtpInput.vue';
import axios from '../../utils/publicApiInstance';

const otpcode = ref(new Array(6).fill(''));
const error = ref(false);
const errorMessage = ref('');
const emailError = ref('');
const emailInput = ref('');
const formProcessing = ref(false);

const router = useRouter();
const query = router.currentRoute.value.query;

const isEmailFieldDisabled = computed(() => !!query.email);

if (query.email) {
  emailInput.value = query.email;
}

watch(emailInput, () => {
  validateEmail();
});

const validateEmail = () => {
  const email = emailInput.value;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!email || !emailRegex.test(email)) {
    emailError.value = 'Enter a valid email address.';
    return false;
  }
  emailError.value = '';
  return true;
};

const submitOtp = async () => {
  if (!validateEmail()) return;

  if (!otpcode.value.every((digit) => digit)) {
    error.value = true;
    errorMessage.value = 'Please fill in all OTP fields.';
    return;
  }

  error.value = false;
  formProcessing.value = true;

  try {
    const emailLowerCase = emailInput.value.toLowerCase();

    const formData = {
      email: emailLowerCase,
      token: otpcode.value.join(''),
    };

    const response = await axios.post('/auth/verify', formData);

    if (response.status === 200) {
      router.push({ path: '/login' }).then(() => {
        toast.success('Account verified successfully.', {
          position: 'top-center',
          autoClose: 2000,
          transition: 'zoom',
        });
      });
    } else {
      toast.error('Account verification failed.', {
        position: 'top-center',
        autoClose: 2000,
        transition: 'zoom',
      });
    }
  } catch (error) {
    toast.error(
      error.response?.data?.detail || 'An unexpected error occurred.',
      {
        position: 'top-center',
        autoClose: 2000,
        transition: 'zoom',
      },
    );
  } finally {
    otpcode.value = new Array(6).fill('');
    error.value = false;
    errorMessage.value = '';
    formProcessing.value = false;
  }
};
</script>
