<template>
  <div class="flex">
    <NavBarLayout />

    <div
      class="flex-1 flex flex-col min-h-screen mt-14 md:mt-0 justify-center items-center gap-1 text-base md:text-lg dark:text-white bg-gray-100 dark:bg-black"
      v-if="shouldShowButton"
    >
      <p>Connect your accounts first to see the Insights</p>
      <RouterLink
        to="/team"
        class="px-4 py-2 bg-gray-800 border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest hover:bg-gray-700 focus:bg-gray-700 active:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50 transition ease-in-out duration-150 max-w-fit dark:bg-amber-500"
      >
        Go to Team Settings
      </RouterLink>
    </div>
    <div
      class="flex-1 dark:bg-black min-h-screen bg-gray-100 mt-14 md:mt-0"
      v-else
    >
      <div class="py-5 flex justify-between px-4 flex-col sm:flex-row gap-1">
        <div class="text-2xl font-semibold dark:text-white">Insights</div>
        <div
          @click="openModal"
          class="flex items-center p-2 rounded-lg bg-gray-200 dark:bg-gray-800 shadow-md space-x-4 cursor-pointer"
        >
          <!-- Left section: "Last X days" -->
          <span
            class="text-xs font-semibold text-gray-600 dark:text-gray-300 bg-gray-300 dark:bg-gray-700 px-2 py-1 rounded"
          >
            {{ dayDifference }} days
          </span>

          <!-- Middle section: Main Date Range -->
          <div class="flex flex-col">
            <div class="flex items-center">
              <!-- Main Date Range -->
              <div class="text-sm font-semibold dark:text-white">
                {{ formattedStartDate }} - {{ formattedEndDate }}
              </div>

              <!-- Right section: Dropdown Icon -->
              <div class="ml-2">
                <svg
                  :class="{ 'rotate-180': isModalOpen }"
                  class="transition-transform w-4 h-4 text-gray-600 dark:text-gray-300"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </div>
            </div>
            <!-- Compare Date Range (shown when comparison is active) -->

            <span
              v-if="isCompareChecked"
              class="text-xs text-gray-600 dark:text-gray-400"
            >
              Compare: {{ formattedCompareStartDate }} -
              {{ formattedCompareEndDate }}
            </span>
          </div>
        </div>
      </div>
      <DatePicker
        :compareRequire="true"
        :isCompareChecked="isCompareChecked"
        :isModalOpen="isModalOpen"
        @toggleCompare="toggleCompare"
        @close="closeModal"
      />
      <!-- Layout for insight -->
      <InsightLayout />
    </div>
  </div>
</template>
<script setup>
import NavBarLayout from '@/layouts/NavBarLayout.vue';
import DatePicker from '@/components/DatePicker.vue';
import {
  checkConnectorsMissing,
  getDateDifference,
} from '@/utils/helperFunctions';
import { computed, ref, watch } from 'vue';
import { useDateStore } from '@/stores/dateStore';
import InsightLayout from '@/layouts/InsightLayout.vue';
import { useTeamStore } from '@/stores/teamStore';
import { RouterLink } from 'vue-router';

const dateStore = useDateStore();
const teamStore = useTeamStore();

const startDate = ref(dateStore.insightDate.currentStart);
const endDate = ref(dateStore.insightDate.currentEnd);
const compareStartDate = ref(dateStore.insightDate.pastStart);
const compareEndDate = ref(dateStore.insightDate.pastEnd);
const isModalOpen = ref(false);
const isCompareChecked = ref(true);

const currentTeam = ref(teamStore.current_team);
watch(
  () => teamStore.current_team,
  (newTeam) => {
    currentTeam.value = newTeam;
  },
  {
    immediate: true,
  },
);

watch(
  dateStore.insightDate,
  () => {
    startDate.value = dateStore.insightDate.currentStart;
    endDate.value = dateStore.insightDate.currentEnd;
    compareStartDate.value = dateStore.insightDate.pastStart;
    compareEndDate.value = dateStore.insightDate.pastEnd;
  },
  {
    immediate: true,
    deep: true,
  },
);
const shouldShowButton = computed(() => {
  if (currentTeam?.value?.id) {
    return checkConnectorsMissing(currentTeam.value);
  }
  return true;
});

const dayDifference = computed(() => {
  return getDateDifference(startDate.value, endDate.value);
});

const formattedStartDate = computed(() => {
  const options = { day: 'numeric', month: 'short' };
  return new Date(startDate.value).toLocaleDateString('en-US', options);
});

const formattedEndDate = computed(() => {
  const options = { day: 'numeric', month: 'short', year: 'numeric' };
  return new Date(endDate.value).toLocaleDateString('en-US', options);
});

const formattedCompareStartDate = computed(() => {
  const options = { day: 'numeric', month: 'short' };
  return new Date(compareStartDate.value).toLocaleDateString('en-US', options);
});

const formattedCompareEndDate = computed(() => {
  const options = { day: 'numeric', month: 'short', year: 'numeric' };
  return new Date(compareEndDate.value).toLocaleDateString('en-US', options);
});
const openModal = () => {
  isModalOpen.value = true;
};

const closeModal = () => {
  isModalOpen.value = false;
};

const toggleCompare = () => {
  isCompareChecked.value = !isCompareChecked.value;
};
</script>
