import { calculateDate } from './helperFunctions';

export const presets = [
  {
    label: 'Past 7 days',
    startDate: calculateDate(-8),
    endDate: calculateDate(-1),
  },
  {
    label: 'Past 14 days',
    startDate: calculateDate(-15),
    endDate: calculateDate(-1),
  },
  {
    label: 'Past 30 days',
    startDate: calculateDate(-31),
    endDate: calculateDate(-1),
  },
  {
    label: 'Past 90 days',
    startDate: calculateDate(-91),
    endDate: calculateDate(-1),
  },
];

export const blinkitColumns = {
  product_listing: [
    'Date',
    'Campaign Name',
    'Targeting Type',
    'Targeting Value',
    'Match Type',
    'Most Viewed Position',
    'Pacing Type',
    'CPM',
    'Impressions',
    'Direct ATC',
    'Indirect ATC',
    'Direct Quantities Sold',
    'Indirect Quantities Sold',
    'Direct Sales',
    'Indirect Sales',
    'New Users Acquired',
    'Estimated Budget Consumed',
    'Direct RoAS',
    'Total RoAS',
  ],
  banner_listing: [
    'Date',
    'Campaign Name',
    'Targeting Type',
    'Targeting Value',
    'Match Type',
    'CPM',
    'Impressions',
    'Reach',
    'CTR',
    'Unique Clicks',
    'Budget Consumed',
  ],
  product_recommendation: [
    'Date',
    'Campaign Name',
    'Pacing Type',
    'CPM',
    'Impressions',
    'Direct ATC',
    'Indirect ATC',
    'Direct Quantities Sold',
    'Indirect Quantities Sold',
    'Direct Sales',
    'Indirect Sales',
    'New Users Acquired',
    'Estimated Budget Consumed',
    'Direct RoAS',
    'Total RoAS',
  ],
};
