<template>
  <form @submit.prevent="logout">
    <button>Log Out</button>
  </form>
</template>

<script setup>
import { toast } from 'vue3-toastify';
import { useAuthStore } from '@/stores/authStore';
import { useUserStore } from '@/stores/userStore';
import { useRouter } from 'vue-router';
import { useTeamStore } from '@/stores/teamStore';
import { useConnectorStore } from '@/stores/connectorStore';
import { useDateStore } from '@/stores/dateStore';

const authStore = useAuthStore();
const userStore = useUserStore();
const teamStore = useTeamStore();
const connectorStore = useConnectorStore();
const dateStore = useDateStore();
const router = useRouter();
const logout = () => {
  authStore.logout();
  userStore.clearUser();
  teamStore.clearTeam();
  connectorStore.clearStore();
  dateStore.clear();
  router.push({ name: 'Login' }).then(() => {
    toast('Logged out successfully!', {
      theme: 'light',
      transition: 'zoom',
      type: 'success',
      autoClose: 2000,
      position: 'top-center',
      dangerouslyHTMLString: true,
    });
  });
};
</script>
