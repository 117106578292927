<template>
  <div
    v-if="loading"
    class="flex flex-col justify-center items-center gap-2 w-full p-8 bg-gray-100 dark:bg-gray-900 border border-gray-200 dark:border-gray-700 rounded-xl shadow-lg text-black dark:text-white"
  >
    <div class="animate-pulse"><Upload /></div>
    Uploading ...
  </div>

  <div
    v-else-if="completed"
    class="text-green-500 flex flex-col justify-center items-center gap-2 w-full p-8 bg-gray-100 dark:bg-gray-900 border border-gray-200 dark:border-gray-700 rounded-xl shadow-lg"
  >
    File Uploaded Successfully
  </div>

  <div
    v-else
    class="w-full p-4 md:p-8 bg-gray-100 dark:bg-gray-900 border border-gray-200 dark:border-gray-700 rounded-xl shadow-lg"
    @dragover="dragover"
    @dragleave="dragleave"
    @drop="drop"
  >
    <input
      type="file"
      name="file"
      id="fileInput"
      class="hidden"
      @change="onChange"
      ref="fileInput"
      accept=".csv"
    />

    <label
      for="fileInput"
      class="block text-center text-base md:text-lg font-semibold text-gray-700 dark:text-gray-200 cursor-pointer"
    >
      <div v-if="isDragging" class="text-blue-500 dark:text-blue-400 underline">
        Release to drop files here.
      </div>
      <div v-else>
        <div v-if="files.length">Continue to upload the file</div>
        <div v-else>
          Drop your CSV files here or
          <u class="text-blue-500 dark:text-blue-400">click here</u> to upload.
        </div>
      </div>
    </label>

    <!-- File preview and removal -->
    <div class="mt-4 max-w-full" v-if="files.length">
      <div
        class="flex items-center justify-between p-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md mb-2"
      >
        <div
          class="truncate text-gray-800 dark:text-gray-300 text-sm md:text-base"
          :title="files[0].name"
        >
          {{ makeName(files[0].name) }}
        </div>

        <div class="flex items-center gap-2">
          <div
            @click="showPreview = !showPreview"
            class="cursor-pointer text-xs md:text-sm"
            v-if="previewData.length"
          >
            <div
              v-if="showPreview"
              class="flex items-center gap-2 dark:text-white"
            >
              <EyeOff size="18" />Hide Data Preview
            </div>
            <div v-else class="flex items-center gap-2 dark:text-white">
              <Eye size="18" />Show Last 5 Rows
            </div>
          </div>
          <div
            class="text-red-500 hover:text-red-700 dark:text-red-400 dark:hover:text-red-600 px-2"
            type="button"
            @click="remove"
            title="Remove file"
          >
            <Delete size="18" />
          </div>
        </div>
      </div>
    </div>

    <div v-if="missingColumns.length" class="mt-4">
      <h3 class="text-base font-semibold text-red-500">Missing Columns:</h3>
      <ul
        class="list-none list-inside text-red-500 text-sm grid md:grid-cols-3"
      >
        <li
          v-for="(column, index) in missingColumns"
          :key="index"
          class="flex items-center gap-2 border m-1 border-red-400 rounded dark:border-red-700"
        >
          <X size="16" />{{ column }}
        </li>
      </ul>
    </div>
    <div v-if="duplicateDates.length" class="mt-4">
      <h3 class="text-base font-semibold text-red-500">Duplicate Dates:</h3>
      <ul
        class="list-none list-inside text-red-500 text-sm grid grid-cols-2 md:grid-cols-4"
      >
        <li
          v-for="(column, index) in duplicateDates"
          :key="index"
          class="flex items-center gap-2 m-1"
        >
          <CalendarX2 size="16" />{{ column }}
        </li>
      </ul>
    </div>
    <!-- Data Preview Table -->
    <div class="grid">
      <div
        class="mt-4 overflow-x-auto border border-gray-200 rounded dark:border-gray-600 dark:text-white custom-scrollbar"
        v-if="showPreview && previewData.length"
      >
        <table
          class="min-w-full bg-white divide-y divide-gray-200 dark:bg-slate-800 dark:divide-gray-800"
        >
          <thead>
            <tr class="bg-gray-50">
              <th
                v-for="column in allColumns"
                :key="column"
                class="px-3 py-2 text-left text-xs font-medium text-gray-700 dark:text-gray-200 uppercase tracking-wider bg-green-200 dark:bg-green-800"
              >
                {{ column }}
              </th>
            </tr>
          </thead>
          <tbody
            class="bg-white divide-y divide-gray-100 dark:bg-slate-800 dark:divide-gray-700"
          >
            <tr
              v-for="(row, index) in limitedPreviewData"
              :key="index"
              class="hover:bg-gray-50 dark:hover:bg-slate-900"
            >
              <td
                v-for="column in allColumns"
                :key="column"
                class="px-3 py-2 text-sm text-gray-800 dark:text-gray-300 whitespace-nowrap"
              >
                {{ row[column] || '—' }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Upload button -->
    <button
      class="mt-4 px-4 py-2 bg-blue-500 dark:bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-600 dark:hover:bg-blue-700 disabled:opacity-50 w-full flex justify-center items-center gap-2"
      @click="uploadFiles"
      :disabled="!files.length || missingColumns.length"
    >
      <CloudUpload />
      {{ missingColumns.length ? 'Re-Upload File' : 'Upload File' }}
    </button>
  </div>
</template>

<script setup>
import { useTeamStore } from '@/stores/teamStore';
import axiosInstance from '@/utils/privateApiInstance';
import {
  CloudUpload,
  X,
  Upload,
  EyeOff,
  Eye,
  Delete,
  CalendarX2,
} from 'lucide-vue-next';
import { computed, ref, watch } from 'vue';
import { toast } from 'vue3-toastify';
import { useRoute, useRouter } from 'vue-router';
import { useConnectorStore } from '@/stores/connectorStore';
const connectorStore = useConnectorStore();
const isDragging = ref(false);
const files = ref([]);
const fileInput = ref(null);
const teamStore = useTeamStore();
const missingColumns = ref([]);
const duplicateDates = ref([]);
const route = useRoute();
const router = useRouter();
const currentTeam = ref(teamStore.current_team);
const loading = ref(false);
const completed = ref(false);
const allColumns = ref([]);
const previewData = ref([]);
const showPreview = ref(false);
const propsData = defineProps(['data']);
const limitedPreviewData = computed(() =>
  previewData.value ? previewData.value.slice(1).slice(-5) : [],
);

const validator = (file) => file && file.name.endsWith('.csv');

watch(
  () => teamStore.current_team,
  (newTeam) => {
    currentTeam.value = newTeam;
  },
  {
    immediate: true,
    deep: true,
  },
);

const onChange = () => {
  if (!validator(fileInput.value.files[0])) {
    toast.error('Only CSV files are accepted');
    remove();
    return;
  }
  files.value = Array.from(fileInput.value.files);
  parseFile();
};

const makeName = (name) => {
  const parts = name.split('.');
  return `${parts[0].substring(0, 20)}...${parts.pop()}`;
};

const remove = () => {
  files.value = [];
  duplicateDates.value = [];
  missingColumns.value = [];
  previewData.value = [];
  fileInput.value.value = null;
  showPreview.value = false;
};

const dragover = (e) => {
  e.preventDefault();
  isDragging.value = true;
};

const dragleave = () => {
  isDragging.value = false;
};

const drop = (e) => {
  e.preventDefault();
  fileInput.value.files = e.dataTransfer.files;
  if (!validator(fileInput.value.files[0])) {
    toast.error('Only CSV files are accepted');
    remove();
  }

  isDragging.value = false;
};

const uploadFiles = async () => {
  if (files.value.length === 0) {
    toast.error('Please select a file');
    return;
  }

  const formData = new FormData();
  formData.append('file', files.value[0]);

  const connectorType = route.query.name.toLowerCase();
  const url = propsData?.data
    ? `/${connectorType}/${currentTeam.value.id}/${propsData.data}`
    : `/${connectorType}/${currentTeam.value.id}`;

  try {
    loading.value = true;
    const response = await axiosInstance.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (response.status === 200) {
      completed.value = true;
      connectorStore.setRecentFetchPending();
      toast.success('File uploaded successfully', {
        autoClose: 2000,
        onClose: () => {
          router.push('/team');
        },
      });
    }
  } catch (error) {
    showPreview.value = false;
    if (error?.response.status === 406) {
      missingColumns.value = error.response.data.detail.missing_cols;
      toast.error('Missing required columns in the uploaded file');
    } else if (error?.response.status === 409) {
      duplicateDates.value = error.response.data.detail.duplicate_dates;
      toast.error('Duplicate Data for some Dates');
    } else {
      console.error('Upload failed:', error);
      toast.error(error.response.data.detail);
    }
  } finally {
    loading.value = false;
  }
};

async function parseFile() {
  if (files.value.length === 0) return;

  try {
    const data = await readFile(files.value[0]);
    const headers = data[0];

    allColumns.value = headers;
    previewData.value = data.slice(1).map((row) => {
      return headers.reduce((acc, header, index) => {
        acc[header] = row[index] || '';
        return acc;
      }, {});
    });
  } catch (error) {
    console.error('Error reading file:', error);
  }
}

function readFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const text = e.target.result;
      const rows = text
        .trim()
        .split('\n')
        .map((row) => row.split(','));
      resolve(rows);
    };
    reader.onerror = (error) => reject(error);
    reader.readAsText(file);
  });
}
</script>
<style scoped>
.custom-scrollbar::-webkit-scrollbar {
  width: 1vh;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #c8c8c8;
  border-radius: 1vh;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #979899;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.dark .custom-scrollbar::-webkit-scrollbar-track {
  background-color: #1f2937;
}

.dark .custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #94a3b8;
}

.dark .custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #64748b;
}
</style>
