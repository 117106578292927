<template>
  <div
    class="bg-gradient-to-r from-blue-50 to-indigo-100 dark:from-gray-900 dark:to-gray-800 shadow-xl border border-gray-300 dark:border-gray-700 rounded-xl p-6 w-full grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-6"
  >
    <!-- Left Column: Instructions -->
    <div class="flex flex-col gap-4 p-3">
      <h2
        class="text-lg md:text-2xl font-bold text-indigo-700 dark:text-indigo-400 flex items-center"
      >
        <span class="mr-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            class="w-6 h-6 text-indigo-500 dark:text-indigo-300"
            viewBox="0 0 24 24"
          >
            <path
              d="M12 2a10 10 0 1 1-10 10A10 10 0 0 1 12 2zm1 15h-2v-2h2zm0-4h-2V7h2z"
            ></path>
          </svg>
        </span>
        Add Your Brand Keywords
      </h2>
      <p
        class="text-gray-800 dark:text-gray-200 text-sm md:text-base leading-relaxed"
      >
        This helps us make your data more accurate and show insights around your
        brand searches. You can add variations on how a user search for your
        brand online, for example, Google could be searched as
        <i class="font-medium">"Gogle"</i>,
        <i class="font-medium">"Googgle"</i>,
        <i class="font-medium">"Googlle"</i>,
        <i class="font-medium">"Gooogle"</i>, etc.
      </p>

      <ul
        class="space-y-1 text-gray-700 dark:text-gray-300 text-sm md:text-base"
      >
        <li class="flex items-center space-x-3">
          <SquareCheckBig :size="18" />
          <span>Choose specific, descriptive keywords.</span>
        </li>
        <li class="flex items-center space-x-3">
          <SquareCheckBig :size="18" />
          <span>Avoid common or overly generic terms.</span>
        </li>
        <li class="flex items-center space-x-3">
          <SquareCheckBig :size="18" />
          <span>Add custom keywords if needed.</span>
        </li>
      </ul>
    </div>
    <!-- Right Column: Input Form -->
    <div class="flex flex-col gap-4 p-3">
      <div
        class="text-lg md:text-2xl font-semibold text-indigo-800 dark:text-indigo-300"
      >
        Manage Your Keywords
      </div>
      <form @submit.prevent="handleSubmit" class="space-y-4">
        <!-- Suggested Keywords -->
        <div v-if="suggested.length" class="space-y-2 text-sm md:text-base">
          <h3 class="text-gray-700 dark:text-gray-300 font-medium">
            Suggested Keywords:
          </h3>
          <div class="flex flex-wrap gap-2">
            <span
              v-for="(keyword, index) in suggested"
              :key="index"
              class="inline-block px-3 py-1 bg-gray-200 dark:bg-gray-700 text-gray-600 dark:text-gray-200 text-sm font-medium rounded-md shadow-sm cursor-pointer"
              @click="selectSuggestedTag(keyword)"
            >
              {{ keyword }}
            </span>
          </div>
        </div>
        <!-- Multiselect Component -->
        <VueMultiselect
          v-model="value"
          :options="options"
          :searchable="true"
          :multiple="true"
          :close-on-select="true"
          :taggable="true"
          placeholder="Pick or add your Brand Keywords"
          tag-placeholder="Add this as a new keyword"
          label="name"
          :max="10"
          track-by="name"
          :hideSelected="true"
          @tag="addCustomOption"
          class="w-full rounded-lg shadow-sm"
        />
        <div class="grid grid-cols-1 md:grid-cols-2 gap-2 text-xs md:text-sm">
          <button
            type="button"
            class="w-full bg-red-500 hover:bg-red-600 disabled:bg-gray-500 text-white font-semibold py-2 rounded-md transition-all duration-200"
            :disabled="!hasConnectors || value.length === 0"
            @click="resetAllKeywords()"
          >
            Replace All Existing
          </button>
          <button
            type="submit"
            class="w-full bg-blue-500 hover:bg-blue-600 disabled:bg-gray-500 text-white font-semibold py-2 rounded-md transition-all duration-200"
            :disabled="value.length === 0"
          >
            Add New Ones
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script setup>
import { useTeamStore } from '@/stores/teamStore';
import axiosInstance from '@/utils/privateApiInstance';
import { SquareCheckBig } from 'lucide-vue-next';
import { ref, watch } from 'vue';
import VueMultiselect from 'vue-multiselect';
import { toast } from 'vue3-toastify';
const value = ref([]);
const options = ref([]);
const suggested = ref([]);
const hasConnectors = ref(false);
const teamStore = useTeamStore();
const currentTeam = ref(teamStore.current_team);
const selectSuggestedTag = (newTag) => {
  const normalizedTag = newTag.toLowerCase().trim();
  if (
    value.value &&
    value.value.some((item) => item.name.toLowerCase() === normalizedTag)
  ) {
    toast.info('Keyword Already Selected');
    return;
  }
  if (!/^[A-Za-z]+$/.test(normalizedTag)) {
    toast.error('Keyword should contain only letters');
    return;
  }

  const newOption = { name: normalizedTag };
  if (
    options.value &&
    !options.value.some((item) => item.name.toLowerCase() === normalizedTag)
  ) {
    options.value.push(newOption);
  }
  if (value.value && !value.value.find((item) => item.name == newOption.name)) {
    value.value.push(newOption);
  }
};
const addCustomOption = (newTag) => {
  const normalizedTag = newTag.toLowerCase().trim();
  if (
    value.value &&
    value.value.some((item) => item.name.toLowerCase() === normalizedTag)
  ) {
    toast.info('Keyword Already Selected');
    return;
  }
  if (!/^[A-Za-z]+$/.test(normalizedTag)) {
    toast.error('Keyword should contain only letters');
    return;
  }
  const newOption = { name: normalizedTag };
  if (
    options.value &&
    !options.value.some((item) => item.name.toLowerCase() === normalizedTag)
  ) {
    options.value.push(newOption);
  }
  if (value.value && !value.value.find((item) => item.name == newOption.name)) {
    value.value.push(newOption);
  }
};
const fetchTeamsKeywords = async (team) => {
  try {
    options.value = [];
    value.value = [];
    const response = await axiosInstance.get(
      `/team/${currentTeam.value.id}/brand-keywords`,
    );
    if (response.data.length) {
      const keywords = response.data.map((keyword) => ({ name: keyword }));
      options.value = keywords;
      value.value = keywords;
      hasConnectors.value = true;
    }
  } catch (error) {
    options.value = [];
    value.value = [];
    toast.error('Error fetching team keywords');
    console.error(error);
    hasConnectors.value = false;
  }
};
const handleSubmit = async () => {
  try {
    const keywords = value.value.map((item) => item.name);
    const response = await axiosInstance.post(
      `/team/${currentTeam.value.id}/brand-keywords`,
      { keywords },
    );
    if (response.status === 201) {
      toast.success('Keywords Added Successfully');
      fetchTeamsKeywords(currentTeam.value);
    }
  } catch (error) {
    toast.error('Failed to add keywords');
    console.error(error);
  }
};
const generateSuggestions = (teamName) => {
  if (!teamName) return;
  suggested.value =
    teamName.length < 3
      ? [
          `${teamName.slice(0, 3).toLowerCase()}`,
          `${teamName.slice(0, 4).toLowerCase()}`,
          `${teamName.slice(0, 5).toLowerCase()}`,
        ]
      : [
          `${teamName.slice(0, 2).toLowerCase()}`,
          `${teamName.slice(0, 3).toLowerCase()}`,
        ];
};
const resetAllKeywords = async () => {
  try {
    const keywords = value.value.map((item) => item.name);
    const response = await axiosInstance.put(
      `/team/${currentTeam.value.id}/brand-keywords`,
      { keywords },
    );
    if (response.status === 200) {
      toast.success('Keywords Updated');
      fetchTeamsKeywords(currentTeam.value);
    }
  } catch (error) {
    toast.error('Failed to update keywords');
    console.error(error);
  }
};
watch(
  () => teamStore.current_team,
  (newTeam) => {
    currentTeam.value = newTeam;
    if (newTeam) {
      fetchTeamsKeywords(newTeam);
      generateSuggestions(newTeam.name);
    }
  },
  { deep: true, immediate: true },
);
</script>
